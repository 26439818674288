import classNames from 'classnames';
import type { IconProps } from '@cardo/types';

export function BarChart({ className, ...props }: IconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
      className={classNames('stroke-current fill-current stroke-0', className)}
      {...props}
    >
      <path
        d="M15.75 3V21H21.75V3H15.75ZM17.25 4.5H20.25V19.5H17.25V4.5ZM2.25 7.5V21H8.25V7.5H2.25ZM3.75 9H6.75V19.5H3.75V9ZM9 12V21H15V12H9ZM10.5 13.5H13.5V19.5H10.5V13.5Z"
        fill="currentColor"
      />
    </svg>
  );
}
