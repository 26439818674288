import classNames from 'classnames';
import type { IconProps } from '@cardo/types';

export function Hotel({ className, ...props }: IconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
      className={classNames('stroke-current fill-current stroke-0', className)}
      {...props}
    >
      <path
        d="M7.5 3L6 4.5L7.5 6L9 4.5L7.5 3ZM9 4.5L10.5 6L12 4.5L10.5 3L9 4.5ZM12 4.5L13.5 6L15 4.5L13.5 3L12 4.5ZM15 4.5L16.5 6L18 4.5L16.5 3L15 4.5ZM16.5 6H3.75V21H20.25V6H16.5ZM5.25 7.5H18.75V19.5H14.25V15.75H9.75V19.5H5.25V7.5ZM6.75 9V10.5H8.25V9H6.75ZM9.75 9V10.5H11.25V9H9.75ZM12.75 9V10.5H14.25V9H12.75ZM15.75 9V10.5H17.25V9H15.75ZM6.75 11.25V12.75H8.25V11.25H6.75ZM9.75 11.25V12.75H11.25V11.25H9.75ZM12.75 11.25V12.75H14.25V11.25H12.75ZM15.75 11.25V12.75H17.25V11.25H15.75ZM6.75 13.5V15H8.25V13.5H6.75ZM9.75 13.5V15H11.25V13.5H9.75ZM12.75 13.5V15H14.25V13.5H12.75ZM15.75 13.5V15H17.25V13.5H15.75ZM6.75 15.75V17.25H8.25V15.75H6.75ZM15.75 15.75V17.25H17.25V15.75H15.75ZM11.25 17.25H12.75V19.5H11.25V17.25Z"
        fill="currentColor"
      />
    </svg>
  );
}
