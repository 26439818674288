import classNames from 'classnames';
import type { IconProps } from '@cardo/types';

export function Plane({ className, ...props }: IconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
      className={classNames('stroke-current stroke-0', className)}
      {...props}
    >
      <path
        d="M19.2891 3.58593C18.999 3.61229 18.7178 3.69726 18.4453 3.84374L14.9062 5.71874L10.125 4.54686L9.84375 4.47655L9.5625 4.61718L7.94531 5.55468L6.98438 6.11718L7.875 6.79686L9.91406 8.34374L7.35938 9.70311L4.61719 8.46093L4.28906 8.3203L3.96094 8.48436L2.64844 9.18749L1.78125 9.65624L2.4375 10.3594L6.49219 14.7187L6.86719 15.1641L7.38281 14.8828L11.25 12.8203L10.4766 17.0859L10.2188 18.4453L11.5078 17.9062L13.4297 17.0859L13.7109 16.9687L13.8281 16.6875L16.6172 9.91405L20.5547 7.80468C21.6387 7.22167 22.0752 5.84179 21.4922 4.7578C21.2021 4.21581 20.707 3.84667 20.1562 3.67968C19.8809 3.59765 19.5791 3.55956 19.2891 3.58593ZM19.4297 5.08593C19.5234 5.08007 19.6201 5.083 19.7109 5.10936C19.8926 5.16503 20.0566 5.27636 20.1562 5.46093C20.3555 5.833 20.2236 6.26952 19.8516 6.46874L15.6562 8.74218L15.4219 8.85936L15.3281 9.11718L12.5391 15.8203L12.2109 15.9609L13.0078 11.5312L13.2891 10.0078L11.9297 10.7344L7.19531 13.2891L4.21875 10.0547L4.33594 9.98436L7.07812 11.2031L7.42969 11.3672L7.73438 11.1797L19.1484 5.15624C19.2422 5.10643 19.3359 5.09179 19.4297 5.08593ZM10.0312 6.0703L12.9141 6.77343L11.3672 7.5703L9.67969 6.28124L10.0312 6.0703ZM2.25 19.5V21H21.75V19.5H2.25Z"
        fill="currentColor"
      />
    </svg>
  );
}
