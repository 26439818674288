import classNames from 'classnames';
import type { IconProps } from '@cardo/types';

export function Suitcase({ className, ...props }: IconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
      className={classNames('stroke-current fill-current stroke-0', className)}
      {...props}
    >
      <path
        d="M10.5 2.25C9.67969 2.25 9 2.92969 9 3.75V4.5H6.75V3.75H5.25V4.5H3.75C2.92969 4.5 2.25 5.17969 2.25 6V18C2.25 18.8203 2.92969 19.5 3.75 19.5H20.25C21.0703 19.5 21.75 18.8203 21.75 18V6C21.75 5.17969 21.0703 4.5 20.25 4.5H18.75V3.75H17.25V4.5H15V3.75C15 2.92969 14.3203 2.25 13.5 2.25H10.5ZM10.5 3.75H13.5V4.5H10.5V3.75ZM3.75 6H20.25V18H18.75V6.75H17.25V18H6.75V6.75H5.25V18H3.75V6Z"
        fill="currentColor"
      />
    </svg>
  );
}
